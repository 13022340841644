<template>
  <v-row class="pb-10" v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Proveedores" :breadcrumbs="breadcrumbs" :dense="true" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_supplier'])">
        <v-tooltip color="bgSearch" right max-width="185px" transition="scale-transition" open-delay="1000">
          <template v-slot:activator="{ on }">
            <span class="fontBody--text body-1" v-on="on">
              <v-btn class="ml-2" width="35" outlined @click="mode = !mode" :disabled="!suppliersList.length || Object.keys($route?.query).length === 0">
              <v-icon size="19">mdi-{{ !mode ? 'view-grid-outline' : 'table'}}</v-icon>
            </v-btn>
            </span>
          </template>
          <span class="d-block px-3 py-2">{{ mode ? 'Ver modo tabla' : 'Ver modo grilla' }}</span>
        </v-tooltip>
        <template>
          <v-menu v-if="mode" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" outlined v-bind="attrs" v-on="on" :disabled="!suppliersList.length || Object.keys($route?.query).length === 0">
                <v-icon left>mdi-checkbox-outline </v-icon>Selecciona<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item class="px-3" @click="selectedItem('all')" :disabled="!Object.keys($route.query).length" :input-value="$store.state.documents.sendTypeToggleDropdown === 'all'" :ripple="false">
                <v-list-item-title :class="['body-2', {'primary--text' : actionSelected === 'all'}]">Todos</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" :disabled="!Object.keys($route.query).length || !!$route.query.ordering" @click="selectedItem('filtered')" :input-value="$store.state.documents.sendTypeToggleDropdown === 'filtered'" :ripple="false">
                <v-list-item-title :class="['body-2', {'primary--text' : actionSelected === 'filtered'}]">Filtrados</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" :disabled="!Object.keys($route.query).length" :input-value="$store.state.documents.sendTypeToggleDropdown === 'perPage'" @click="selectedItem('perPage')" :ripple="false">
                <v-list-item-title :class="['body-2', {'primary--text' : actionSelected === 'perPage'}]">Primera página</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu v-if="$helpers.hasSomePermission(['add_suppliergroup'])" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item class="px-3" @click="handleGroup('create')" :ripple="false" :disabled="!selected.length">
                <v-list-item-title class="body-2">Crear segmento</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="handleGroup('add')" :ripple="false" :disabled="!selected.length">
                <v-list-item-title class="body-2">Agregar a segmento</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-query-builder class="mx-2" :query-map="filters" model="suppliers" :active.sync="activeFilter" />
          <v-btn color="primary" @click="dialogSupplier=true"><v-icon left>mdi-human-dolly</v-icon>Crear proveedor</v-btn>
        </template>
      </template>
    </mini-header>
    <!-- end header -->

    <v-col cols="12" class="px-0">
      <PermissionDenied v-if="!$helpers.hasSomePermission(['view_supplier'])" />
      <skeleton-list v-else-if="loading" />
      <template v-else>
        <v-empty-state v-if="!suppliersList.length || Object.keys($route?.query).length === 0" type="suppliers" id="proveedores" customClass="mt-5" :hiddenDescription="hiddenBtnEmpty">
          <template v-slot:actions>
            <template v-if="!$route.query.ordering && hiddenBtnEmpty">
              <v-btn @click="activeFilter = true" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
              <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
            </template>
          </template>
        </v-empty-state>
        <template v-else class="text-center py-5">
          <v-data-table
            v-if="!mode"
            :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
            :headers="headers"
            :items="suppliersList"
            :items-per-page="pagination"
            mobile-breakpoint
            hide-default-header
            disable-sort
            hide-default-footer
            v-model="selected"
            item-key="id"
            show-select
            checkbox-color="primary"
            @click:row="(suppliersList) => {$router.push({name: 'SupplierRetrieveUpdate', params: {id: suppliersList.id}})}"
            style="cursor: pointer"
            :height="heightTable"
            fixed-header
            :key="reset"
          >
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders
                :headers="headers"
                @sort="setSort"
                @toggleAll="toggleAll"
                @isSelected="isSelected = $event"
                :resetCheckbox="resetCheckbox"
              />
              <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.name`]="{item}">
              <span class="text--secondary font-weight-medium">{{item.name}}</span>
            </template>
            <template v-slot:[`item.tax_settings`]="{item}">
              <template v-if="item.tax_settings?.cl_activity_description">{{item.tax_settings?.cl_activity_description}}</template>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span class="text-right">{{item.created | dateTimeSecond}}</span>
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-menu v-if="$helpers.hasSomePermission(['add_suppliergroup'])" close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-3" @click="hanldeTableAddToGroup(item)" :ripple="false">
                      <v-list-item-title class="body-2">Agregar a segmento</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
          </v-data-table>
          <v-row v-else>
            <v-col v-for="(supplier, index) in suppliersList" :key="`${supplier.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat :to="{ name: 'SupplierRetrieveUpdate', params: { id: supplier.id }}" @click="$store.commit('base/SET_QUERY', $route.query)">
                  <v-card-actions>
                  </v-card-actions>
                  <v-row no-gutters>
                    <v-icon color="primary">mdi-human-dolly</v-icon>
                    <v-spacer />
                    <v-checkbox class="mr-n2" v-model="selected" :value="supplier.id" dense multiple hide-details />
                  </v-row>
                  <span class="d-block mt-2">
                    <v-sheet class="transparent" height="25">
                      <span v-if="supplier.name.length" class="fontBody--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 162px;">{{supplier.name}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-sheet>
                  </span>
                  <span v-if="supplier.tax_id" class="mt-n2 d-block body-1 fontDraw--text d-inline-block text-truncate" style="max-width: 180px;">{{supplier.tax_id}}</span>
                  <span class="d-block mt-n1 body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <VMainPagination titleShowAll="Ver todos los proveedores" :count="count" redirect="SupplierListCreate" />
        </template>
      </template>
    </v-col>

    <!-- dialog new supplier -->
    <v-dialog v-model="dialogSupplier" width="900" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Crear proveedores</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogSupplier=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5">
          <v-row class="pt-2 pb-3" align="center">
            <v-col cols="2">
              <img :src="require(`@/assets/backgrounds/modal-supplier-create.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-13">
              <v-list class="v-list-form-extended pa-0 transparent">
                <v-list-item class="pa-0">
                  <v-list-item-title>Nombre</v-list-item-title>
                  <v-list-item-subtitle class="pl-5">
                    <v-col class="pa-0">
                      <v-text-field v-model.trim="$v.newSupplier.name.$model" :error="$v.newSupplier.name.$error" outlined required single-line dense maxlength="100" hide-details />
                    </v-col>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="px-0 pt-2">
                  <v-list-item-title>RUT</v-list-item-title>
                  <v-list-item-subtitle class="pl-5">
                    <v-col class="pa-0">
                      <v-text-field v-model.trim="$v.newSupplier.tax_id.$model" :error="$v.newSupplier.tax_id.$error" v-dni="newSupplier.country" outlined single-line dense hide-details />
                    </v-col>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="px-0 pt-2">
                  <v-list-item-title>Comuna</v-list-item-title>
                  <v-list-item-subtitle class="pl-5">
                    <v-select v-model="$v.newSupplier.state.$model" :error="$v.newSupplier.state.$error" :items="$store.getters['base/currentStates']" item-text="name" item-value="id" single-line hide-details dense height="30" outlined no-data-text="No existen estados para el país." />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0 pt-2">
                  <v-list-item-title>Actividad económica</v-list-item-title>
                  <v-list-item-subtitle class="pl-5">
                    <v-text-field v-model="$v.newSupplier.tax_settings.cl_activity_description.$model" :error="$v.newSupplier.tax_settings.cl_activity_description.$error" outlined required single-line dense maxlength="64" hide-details />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pa-0 pt-2">
                  <v-list-item-title>Grupo</v-list-item-title>
                  <v-list-item-subtitle class="pl-5">
                    <v-autocomplete
                      v-model="newSupplier.supplier_group_ids"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="groupsList"
                      multiple
                      :chips="false"
                      item-text="name"
                      item-value="id"
                      :prefix="newSupplier.supplier_group_ids.length >= 1 ? `${newSupplier.supplier_group_ids.length === 1 ? `${newSupplier.supplier_group_ids.length} seleccionado` : `${newSupplier.supplier_group_ids.length} seleccionados`}` : ''"
                      no-data-text="No existen grupos para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSupplier=false">Cancelar</v-btn>
          <v-btn color="primary" :loading="$store.state.suppliers.createLoader" @click="postSupplier">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new supplier -->

    <!-- dialog confirm list -->
    <v-dialog v-model="dialogConfirm" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Cambio de lista</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirm=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <span class="d-block">Al cambiar a la lista blanca, todos los documentos de estos proveedores, serán aceptados automáticamente.</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirm=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="handleList" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm list -->

    <!-- dialog new group -->
    <v-dialog v-model="dialogGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Crear segmento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogGroup=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.name.$model" :error="$v.newGroup.name.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-2">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.description.$model" :error="$v.newGroup.description.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <div class="">
            <span class="d-block mt-5 body-1 fontBody--text font-weight-bold">Visibilidad</span>
            <v-radio-group class="mx-0 ml-n1" v-model="newGroup.visibility" dense hide-details>
              <v-radio label="Todos los miembros de la organización" :value="1" hide-details />
              <v-radio label="Algunos miembros de la organización" :value="2" hide-details />
              <v-radio label="Solo yo" :value="3" hide-details />
            </v-radio-group>
          </div>
          <template v-if="![1, 3].includes(newGroup.visibility)">
            <v-list class="pa-0 mt-5 transparent v-list-form-extended">
              <v-list-item class="py-1">
                <v-list-item-title>Compartir con</v-list-item-title>
                <v-list-item-subtitle>
                  <v-col class="pa-0">
                    <v-autocomplete
                      v-model="newGroup.members"
                      :error="$v.newGroup.members.$error"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="membersList"
                      multiple
                      :chips="false"
                      item-text="email"
                      item-value="email"
                      return-object
                      :prefix="newGroup.members.length >= 1 ? `${newGroup.members.length === 1 ? `${newGroup.members.length} seleccionado` : `${newGroup.members.length} seleccionados`}` : ''"
                      no-data-text="No existen miembros para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-row class="ml-1" no-gutters>
              <v-col cols="2"></v-col>
              <v-col class="pa-0 mt-2 pl-6">
                <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in newGroup.members.slice(0,2)" :key="`${member}${i}`" close @click:close="memberRemove(member, index)" color="lightBlue" small label close-icon="mdi-close">
                  {{member.email }}
                </v-chip>
                <template v-if="newGroup.members.length > 2">
                  <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" color="lightBlue" small label close-icon="mdi-close">
                  {{newGroup.members.length - 2}} más
                </v-chip>
                </template>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogGroup=false">Cancelar</v-btn>
          <v-btn color="primary" @click="createGroup" :loading="createGroupLoading">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new group -->

    <!-- dialog add to group -->
    <v-dialog v-model="dialogAddGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Agregar a segmento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogAddGroup=false, groups=[]" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-7 pt-5 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 mt-5 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Segmento</v-list-item-title>
              <v-list-item-subtitle>
                <v-col class="pa-0">
                  <v-autocomplete
                    v-model="groups"
                    hide-details
                    single-line
                    color="primary"
                    dense
                    outlined
                    :items="groupsList"
                    multiple
                    :chips="false"
                    item-text="name"
                    item-value="id"
                    return-object
                    :prefix="groups.length >= 1 ? `${groups.length === 1 ? `${groups.length} seleccionado` : `${groups.length} seleccionados`}` : ''"
                    no-data-text="No existen segmentos para mostrar"
                  >
                    <template v-slot:selection="{ props }">
                      <span v-if="props" style=""></span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-row class="ml-1" no-gutters>
            <v-col cols="2"></v-col>
            <v-col class="pa-0 mt-2 pl-6">
              <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(g, i) in groups.slice(0,2)" :key="`${g}${i}`" close @click:close="groupsRemove(g, i)" color="lightBlue" small label close-icon="mdi-close">
                {{g.name }}
              </v-chip>
              <template v-if="groups.length > 2">
                <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" color="lightBlue" small label close-icon="mdi-close">
                {{groups.length - 2}} más
              </v-chip>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogAddGroup=false, groups=[]">Cancelar</v-btn>
          <v-btn color="primary" @click="addToGroup" :loading="createGroupLoading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog add to group -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { rutValidator } from 'vue-dni'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VQueryBuilder,
    VTableHeaders,
    VEmptyState,
    SkeletonList,
    VMainPagination,
    MiniHeader,
    PermissionDenied
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    reset: 0,
    auxSelectedList: [],
    groups: [],
    newGroup: {
      name: '',
      visibility: 3,
      description: '',
      suppliers: [],
      members: []
    },
    createGroupLoading: false,
    dialogAddGroup: false,
    dialogGroup: false,
    oldSelected: null,
    isClickCard: false,
    isClickCheckbox: false,
    resetCheckbox: 0,
    actionSelected: '',
    selectedList: [],
    selected: [],
    mode: true,
    activeFilter: false,
    welcome: true,
    env: process.env?.VUE_APP_MODE ?? 'ax',
    scroll: 0,
    confirm: '',
    isAccept: false,
    dialogConfirm: false,
    currentList: [],
    current: {},
    newSupplier: {
      name: '',
      tax_id: '',
      supplier_group_ids: [],
      tax_settings: {
        cl_activity_description: ''
      },
      country: 'CL',
      state: ''
    },
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'tax_id',
        label: 'RUT',
        value: null,
        category: 'a',
        operators: [],
        countries: ['PE', 'CL']
      }
    ],
    headers: [
      { text: 'Proveedor', value: 'name', class: 'font-weight-bold', sortable: true },
      { text: 'RUT', value: 'tax_id', sortable: true },
      { text: 'Actividad económica', value: 'tax_settings', sortable: true },
      { text: 'Creado el', value: 'created', align: 'end', sortable: true },
      // { text: 'Lista', value: 'list', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end'}
    ],
    dialogSupplier: false,
    loading: false,
    options: {
      white: ['Sacar de lista blanca', 'Cambiar a lista negra'],
      black: ['Sacar de lista negra', 'Cambiar a lista blanca'],
      null: ['Cambiar a lista blanca', 'Cambiar a lista negra']
    },
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Proveedores'
        }
      ]
    }
  }),
  computed: {
    ...mapState({
      count: state => state.suppliers.suppliersCount,
      suppliersList: state => state.suppliers.suppliersList,
      countriesList: state => state.base.countriesList,
      membersList: state => state.accounts.membersList,
      groupsList: state => state.suppliers.groupsList
    }),
    country () {
      return this.newSupplier.country ? this.countriesList.find(country => country.id === this.newSupplier.country) : null
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.suppliersList.length) return false
      return false
    },
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 800 && this.suppliersList.length < 17) return 'auto'
      return 'calc(100vh - 210px)'
    }
  },
  watch: {
    mode (val) {
      this.reset += 1
      this.selectedList = []
      this.selected = []
      this.auxSelectedList = []
      this.actionSelected = ''
      this.$store.commit('documents/SET_TOOGLE_SENDTYPE', '')
    },
    'newSupplier.country' () {
      this.newSupplier.state = ''
      this.newSupplier.tax_id = ''
    },
    '$store.state.documents.sendTypeToggleDropdown' (val) {
      this.selectedList = []
      if (val?.length === 0) this.selected = []
      else {
        if (['all', 'filtered'].includes(val)) {
          this.selected = this.mode ? this.suppliersList.map(({id}) => id) : this.suppliersList
          this.selectedList = []
        } else {
          this.selected = this.mode ? this.suppliersList.map(({id}) => id) : this.suppliersList
          this.selectedList = [...this.selected]
        }
      }
    },
    dialogSupplier (val) {
      if (val) {
        this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups',
        query: {
            page_size: 9999
          }
        })
      }
    },
    selected (val) {
     if (!this.$store.state.documents.sendTypeToggleDropdown) this.selectedList = !this.mode ? val.map(({id}) => id) : val
    },
    selectedList (val) {
      if (this.$store.state.documents.sendTypeToggleDropdown === 'perPage') {
        this.auxSelectedList = this.suppliersList.map(({id}) => id)
      } else {
        this.auxSelectedList = val
      }
    }
  },
  created () {
    if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length) && this.$helpers.hasSomePermission(['view_supplier'])) {
      this.getList()
    }
  },
  methods: {
    hanldeTableAddToGroup (item = {}) {
      this.selectedList.push(item.id)
      this.handleGroup('add')
    },
    async handleGroup (action = '') {
      if (action === 'create') {
        this.dialogGroup = true
        this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
      } else {
        await this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups',
        query: {
            page_size: 9999
          }
        })
        this.dialogAddGroup = true
      }
    },
    memberRemove (item) {
      const index = this.newGroup.members.indexOf(item)
      if (index >= 0) this.newGroup.members.splice(index, 1)
    },
    groupsRemove (item) {
      const index = this.groups.indexOf(item)
      if (index >= 0) this.groups.splice(index, 1)
    },
    async addToGroup () {
      this.createGroupLoading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers/add_to_groups',
        query: {
          ...currentQuery,
          ...(this.auxSelectedList.length && !Object.keys(currentQuery).length && { id__in: this.auxSelectedList.toString()})
        },
        payload: {
          supplier_group_ids: this.groups.map(({id}) => id)
        }
      })
      .then(() => {
        this.$dialog.message.info('Los proveedores han sido asignados correctamente al segmento.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.groups = []
        this.dialogAddGroup = false
        this.createGroupLoading = false
      })
    },
    createGroup () {
      this.$v.newGroup.$touch()
      if (this.$v.newGroup.$invalid) {
        return false
      }

      this.createGroupLoading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      let payload = this.newGroup
      payload.suppliers = this.selectedList
      if ([1, 3].includes(this.newGroup.visibility)) delete payload.members
      else payload.members = payload.members.map(({id}) => id)

      if (['todos', 'filtrados'].includes(this.actionSelected) || ['filtered', 'all'].includes(this.$store.state.documents.sendTypeToggleDropdown)) delete payload.suppliers
      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers/groups',
        query: currentQuery,
        payload: payload
      })
     .then(() => {
        this.$dialog.message.info('El segmento ha sido creado con éxito.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.newGroup.$reset()
        this.dialogGroup = false
        this.createGroupLoading = false
        this.newGroup = {
          name: '',
          visibility: 3,
          description: '',
          suppliers: [],
          members: []
        }
      })
    },
    selectedItem (type) {
      this.actionSelected = this.oldSelected !== type
      this.$store.commit('documents/SET_TOOGLE_SENDTYPE', this.actionSelected ? type : '')
      this.oldSelected = type
    },
    toggleAll () {
      this.$store.commit('documents/SET_TOOGLE_SENDTYPE', '')
      this.selected = !this.isSelected ? [] : this.suppliersList
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    handleConfirm () {
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.dialogConfirm = true
      } else {
        this.handleList()
      }
    },
    handleList () {
      const index = this.suppliersListPE.findIndex(({id}) => id === this.currentList[1].id)
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.suppliersListPE[index].list = this.currentList[1].list === 'white' ? 'black' : 'white'
      } else {
        this.suppliersListPE[index].list = null
      }
      this.dialogConfirm = false
    },
    async getList () {
      this.welcome = false
      this.loading = true
      await this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers',
        query: this.$route.query
      })
      this.loading = false
    },
    postSupplier () {
      this.$v.newSupplier.$touch()
      if (this.$v.newSupplier.$invalid) {
        return false
      }
      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers',
        payload: this.newSupplier
      })
      .then((response) => {
        this.$router.push({ name: 'SupplierRetrieveUpdate', params: { id: response.data.id } })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogSupplier = false
      })
    }
  },
  validations: {
    newGroup: {
      name: { required, maxLength: maxLength(64) },
      description: { required, maxLength: maxLength(64) },
      visibility: { required },
      members: {
      required: requiredIf(function () {
        return ![1, 3].includes(this.newGroup.visibility)
      })
      }
    },
    newSupplier: {
      name: { required, maxLength: maxLength(100) },
      country: { required },
      state: { required },
      tax_id: {
        required,
        format: function (val) {
          return rutValidator(val)
        },
        maxLength: maxLength(16)
      },
      tax_settings: {
        cl_activity_description: {
          required: requiredIf(function () {
            return this.newSupplier.country === 'CL'
          }),
          maxLength: maxLength(64)
        }
      }
    }
  }
}
</script>